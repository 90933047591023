
export class BookingValidationHelper {
  mailRegx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  customerValidation(customerData) {
    if (!customerData.name || !customerData.name.trim().length) {
      return {error: 'Please enter customer name.', errStatus: true};
    }
    if (!customerData.email || !customerData.email.trim().length) {
      return {error: 'Please enter customer email.', errStatus: true};
    }
    if (customerData.email && !this.mailRegx.test(customerData.email)) {
      return {error: 'Please enter valid email.', errStatus: true};
    }
    if (!this.checkPhoneNumber(customerData.phoneNumber)) {
      return {error: 'Please enter valid contact number.', errStatus: true};
    }
  }

  checkPhoneNumber(data) {
    return this.isValidPhone(data.split('-')[1]);
  }

  isValidPhone(userInput) {
    if (userInput.length <= 10 && userInput.length > 6) {
      const regex = /^[0-9]*$/;
      return regex.test(userInput);
    } else {
      return false;
    }
  }
}
