import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {WidgetService} from "../Services/widget.service";

@Component({
  selector: 'app-gift-card-greetings',
  templateUrl: './gift-card-greetings.component.html',
  styleUrls: ['./gift-card-greetings.component.scss']
})
export class GiftCardGreetingsComponent implements OnInit, OnChanges {
  name;
  @Input() giftCardSettings;
  @Input() selectedIndex;

  constructor(private  widgetService: WidgetService) { }

  ngOnInit(): void {
    this.name = this.widgetService?.billingFormData?.name;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.selectedIndex && changes.selectedIndex.currentValue === 6){
      this.name = this.widgetService.billingFormData.name;
    }
  }

}
