import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NbCardModule, NbCheckboxModule, NbDialogService, NbPopoverModule, NbSpinnerModule, NbThemeModule} from '@nebular/theme';
import {AppRouting} from './app.routing';
import {NbEvaIconsModule} from "@nebular/eva-icons";
import { GiftCardTypeSelectionComponent } from './gift-card-type-selection/gift-card-type-selection.component';
import { GiftCardBuyingComponent } from './gift-card-buying/gift-card-buying.component';
import { GiftCardCartComponent } from './gift-card-cart/gift-card-cart.component';
import { GiftCardRecipientDetailsComponent } from './gift-card-recipient-details/gift-card-recipient-details.component';
import { GiftCardBillingDetailsComponent } from './gift-card-billing-details/gift-card-billing-details.component';
import { GiftCardPaymentComponent } from './gift-card-payment/gift-card-payment.component';
import { GiftCardGreetingsComponent } from './gift-card-greetings/gift-card-greetings.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CountryService} from "./country.service";
import { GiftCardWidgetComponent } from './gift-card-widget/gift-card-widget.component';
import {WidgetService} from "./Services/widget.service";
import {HttpClientModule} from "@angular/common/http";
import {NgxStripeModule} from "ngx-stripe";
import {NebularModule} from "./nebular.module";
import {VenueService} from "./Services/venue-list.service";
import {VenueListComponent} from "./venue-list/venue-list.component";
import {NotificationService} from "./Services/notification.service";

@NgModule({
  declarations: [
    AppComponent,
    GiftCardTypeSelectionComponent,
    GiftCardBuyingComponent,
    GiftCardCartComponent,
    GiftCardRecipientDetailsComponent,
    GiftCardBillingDetailsComponent,
    GiftCardPaymentComponent,
    GiftCardGreetingsComponent,
    PhoneInputComponent,
    GiftCardWidgetComponent,
    VenueListComponent,
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NbThemeModule.forRoot({name: 'corporate'}),
        AppRouting,
        NbEvaIconsModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        NgxStripeModule.forRoot('pk_test_51IOWiSGY2f4VRSCuJym77Ylf6C7sV1rfXTar8P4g24cJS4FLO4SkE6CcgqzKS4M07wdMeht80eul6wdhahEbuLbr00RVpBW8KA'),
        NebularModule,
        NbCardModule,
        NbSpinnerModule,
        NbCheckboxModule,
        NbPopoverModule,
    ],
  providers: [CountryService, WidgetService, VenueService, NotificationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
