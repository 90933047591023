import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {WidgetService} from "../Services/widget.service";
import {GiftCardWidgetModel} from "../../utils/Model/gift-card-widget.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-gift-card-widget',
  templateUrl: './gift-card-widget.component.html',
  styleUrls: ['./gift-card-widget.component.scss']
})
export class GiftCardWidgetComponent implements OnInit {
  bookingStep = 0;
  venueId;
  venueName;
  giftCardSettings: GiftCardWidgetModel;
  primaryColor;
  secondaryColor;
  loading = false;
  constructor(private activatedRoute: ActivatedRoute,
              public widgetService: WidgetService) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((data: any) => {
      this.venueId = data.params.venueId;
      this.getWidgetSettings();
    });
    if (localStorage.getItem("Summary") !== null) {
      localStorage.removeItem('Summary');
    }
    this.venueName = localStorage.getItem("venue");
  }

  async getWidgetSettings() {
    this.loading = true;
    try{
      const res = await  this.widgetService.getWidgetSettings(this.venueId);
      this.giftCardSettings = res;
      this.widgetService.widgetSettings = res;
      const root = document.documentElement;
      this.widgetService.setVenue(this.giftCardSettings.venueId._id);
      this.widgetService.setSelectedVenue(this.giftCardSettings.venueId.venueName);
      this.widgetService.setgateWayName(res.venueId.payment.gateWayName);
      if (this.giftCardSettings.colourScheme?.primaryColor !== null) {
        root.style
          .setProperty('--primaryColor', this.giftCardSettings.colourScheme.primaryColor);
        this.primaryColor = this.giftCardSettings.colourScheme.primaryColor;
      }
      else {
        root.style
          .setProperty('--primaryColor', '#34a853');
      }
      if (this.giftCardSettings.colourScheme.secondaryColor !== null) {
        root.style
          .setProperty('--secondaryColor', this.giftCardSettings.colourScheme.secondaryColor);
        this.secondaryColor = this.giftCardSettings.colourScheme.secondaryColor
      }
      else {
        root.style
          .setProperty('--secondaryColor', '#fbbc05');
      }
      this.loading = false;
    }
    catch (err){
      console.log(err);
      this.loading = false;
    }
  }

  onNextClick() {
    this.bookingStep++;
    this.widgetService.selectedTab = this.bookingStep;
  }

  onBackClick() {
    this.bookingStep--;
    this.widgetService.selectedTab = this.bookingStep;
  }
}
