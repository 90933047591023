import {Component, Input, OnInit} from "@angular/core";
import {VenueService} from '../Services/venue-list.service';
import {Router , ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-venue-list',
  templateUrl: './venue-list.component.html',
  styleUrls: ['./venue-list.component.scss']
})
export class VenueListComponent implements OnInit {
  venueList = [];
  isPaymentDone;
  venueId;
  constructor(private _venueService: VenueService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((data: any) => {
      console.log(data.params.venueId)
      this.venueId = data.params.venueId;
    });
    this.activatedRoute.queryParamMap.subscribe((data: any) => {
      this.isPaymentDone = data.params.payment;
    });
    this._venueService.getVenue(this.venueId)
      .then((res: any) => {
        console.log(res.data)
        this.venueList = res.data;
      })
      .catch(err => {
        console.log(err);
      });
  }

  onVenueSelect(id){
    this.router.navigate(['gift-card/', id]);
  }
}
