import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WidgetService} from "../Services/widget.service";

@Component({
  selector: 'app-gift-card-type-selection',
  templateUrl: './gift-card-type-selection.component.html',
  styleUrls: ['./gift-card-type-selection.component.scss']
})
export class GiftCardTypeSelectionComponent implements OnInit {
  @Output() onNextClickChange = new EventEmitter();
  @Output() onBackClickChange = new EventEmitter();
  @Input() venueName;
  @Input() secondaryColor;
  @Input() primaryColor;
  @Input() giftCardSettings;
  constructor(private widgetService: WidgetService) { }

  ngOnInit(): void {
  }

  onNextClick(cardType) {
    this.onNextClickChange.emit();
    if (cardType === 'physicalCard') {
      this.widgetService.selectedGiftCardType = "Physical Card";
    }
    if (cardType === 'eGiftCard') {
      this.widgetService.selectedGiftCardType = "E-Gift Card";
    }
  }

  onBackClick() {
    this.onBackClickChange.emit();
  }
}
