import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {WidgetService} from "../Services/widget.service";
import {Subject} from "rxjs";

@Component({
  selector: 'app-gift-card-cart',
  templateUrl: './gift-card-cart.component.html',
  styleUrls: ['./gift-card-cart.component.scss']
})
export class GiftCardCartComponent implements OnInit , OnChanges{
  @Output() onNextClickChange = new EventEmitter();
  @Output() onBackClickChange = new EventEmitter();
  @Input() venueName;
  @Input() giftCardSettings;
  cardType;
  cartSummary;
  @Input() selectedIndex;
  venueId;
  isDeleted = false;
  updatedCartSummary;
  totalAmount;
  isLoaded = false;
  postageFee;
  constructor(public widgetService: WidgetService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedIndex && changes.selectedIndex.currentValue === 2){
      this.cardType = this.widgetService.selectedGiftCardType;
      this.cartSummary = this.widgetService.getCartSummary();
      this.totalAmount = this.cartSummary.totalAmount;
      this.postageFee = this.cartSummary.postageFee;
      this.venueId = this.widgetService.getVenue();
      this.isLoaded = true;
    }
  }

  onNextClick() {
    this.onNextClickChange.emit();
  }

  onBackClick() {
    this.onBackClickChange.emit();
  }
  deleteCoupon(index) {
    this.widgetService.deleteCard.next(this.cartSummary.data[index].cardId);
    this.isLoaded = false;
    this.cartSummary.data.splice(index, 1);
    this.cartSummary = JSON.parse(JSON.stringify(this.cartSummary));
    if (this.cartSummary.data.length === 0) {
      this.totalAmount = 0;
      this.isLoaded = true;
    }
    if (this.cartSummary.data.length !== 0) {
      const reqBody = {
        cardType: this.cardType,
        couponCode: '',
        date: this.widgetService.getGiftCardDate(),
        selectedCard: this.cartSummary.data
      };
      this.widgetService.createSummary(this.venueId, reqBody)
        .then((res: any) => {
          this.isDeleted = true;
          this.updatedCartSummary = res;
          this.totalAmount = this.updatedCartSummary.totalAmount;
          this.isLoaded = true;
        })
        .catch(err => {
          this.isLoaded = true;
          console.log(err);
        });
    }
  }
}
