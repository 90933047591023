<div class="container" [nbSpinner]="!isLoaded" nbSpinnerStatus="success" *ngIf="widgetService.selectedTab===2">
  <div>
    <h2 class="card__type d-flex just-center">
      {{cardType}}
    </h2>
  </div>
  <div class="cart">
    <div class="cart__header d-flex just-between align-center">
      <div>
        <h2 class="header-name m-0">Order Summary</h2>
      </div>
      <div>
        <nb-icon class="edit-icon" icon="edit-outline" [options]="{color: ' var(--primaryColor)'}" (click)="onBackClick()"></nb-icon>
      </div>
    </div>
    <div class="gift__card-order--summary" *ngIf="cartSummary">
      <div class=" d-flex just-between align-center flex-wrap" *ngFor="let summary of cartSummary.data;let i = index">
        <div class="order__summary-left d-flex align-center">
          <div>
            <h2 class="order__summary-fonts m-0">{{i+1}}</h2>
          </div>
          <div>
            <h2 class="order__summary-fonts ml-6 mt-0 mb-0">${{summary.pricePerCard}}</h2>
          </div>
          <div>
            <h2 class="order__summary-fonts ml-6 mt-0 mb-0">{{"  " + cardType}}</h2>
          </div>
        </div>
        <div *ngIf="summary.postageFee">
          <h2 class="order__summary-fonts ml-6 mt-0 mb-0">${{summary.postageFee + "  PostageFee"}}</h2>
        </div>
        <div *ngIf="summary.totalDiscount">
          <h2 class="order__summary-fonts ml-6 mt-0 mb-0">${{summary.totalDiscount + "  Discounts"}}</h2>
        </div>
        <div class="order__summary-right d-flex align-center">
          <div class="mr-5">
            <h2 class="order__summary-fonts m-0">QTY:{{summary.totalCards}}</h2>
          </div>
          <div>
            <h2 class="order__summary-fonts ml-6 mt-0 mb-0">Price:{{summary.pricePerCard * summary.totalCards}}</h2>
          </div>
          <div class="ml-6">
            <nb-icon class="delete-icon" icon="close-circle-outline" [options]="{color: 'red'}" (click)="deleteCoupon(i)"></nb-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="order__total">
      <div class="d-flex just-end">
        <div class="d-flex just-between">
          <div>
            <h2 class="total-fonts m-0">
              Total
            </h2>
          </div>
          <span class="ml-20 mr-20 d-flex align-center">:</span>
          <div>
            <h2 class="total-fonts m-0">
              ${{totalAmount}}
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex just-end mt-10" *ngIf="cartSummary">
  <div>
    <button class="cancel__button" (click)="onBackClick()" [ngStyle]="{'border-color':this.giftCardSettings.colourScheme.primaryColor}">Back</button>
  </div>
  <div *ngIf="cartSummary.data.length !== 0">
    <button class="next__button ml-10" [disabled]="cartSummary.data.length === 0" [ngStyle]="{'border-color':this.giftCardSettings.colourScheme.primaryColor}" (click)="onNextClick()">Next</button>
  </div>
  <div *ngIf="cartSummary.data.length === 0">
    <button class="next__button-disabled ml-10" [ngStyle]="{'border-color':this.giftCardSettings.colourScheme.primaryColor}">Next</button>
  </div>
</div>
