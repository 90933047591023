import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {WidgetService} from "../Services/widget.service";
import {BookingValidationHelper} from "../../utils/helper/validation.helper";
import {NotificationService, NotificationStatus} from "../Services/notification.service";

@Component({
  selector: 'app-gift-card-billing-details',
  templateUrl: './gift-card-billing-details.component.html',
  styleUrls: ['./gift-card-billing-details.component.scss']
})
export class GiftCardBillingDetailsComponent implements OnInit , OnChanges{
  @Output() onNextClickChange = new EventEmitter();
  @Output() onBackClickChange = new EventEmitter();
  @Input() venueName;
  @Input() giftCardSettings;
  @Input() selectedIndex;
  cardType;
  phoneNumber;
  formData = {
    'name' : '',
    'phoneNumber': '+61-',
    'email': '',
  };
  totalAmount;
  isAcceptedTermsAndConditions = false;
  constructor(private widgetService: WidgetService,
              private notificationService: NotificationService) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.selectedIndex && changes.selectedIndex.currentValue === 4){
      this.cardType = this.widgetService.selectedGiftCardType;
      this.totalAmount = this.widgetService.getCartSummary().totalAmount;
    }
  }

  onNextClick() {
    const error = new BookingValidationHelper().customerValidation(this.formData);
    if (error && error.errStatus) {
      this.notificationService.showNotification(NotificationStatus.ERROR, error.error);
      return true;
    }
    if(!this.isAcceptedTermsAndConditions){
      this.notificationService.showNotification('warning', "You need to agree to the terms and conditions before proceeding");
      return;
    }
    this.widgetService.billingFormData = this.formData;
    this.onNextClickChange.emit();
  }

  onBackClick() {
    this.onBackClickChange.emit();
  }
}
