<div class="payments__details-verify d-flex mt-30 container" [nbSpinner]="!isLoaded" nbSpinnerStatus="success" *ngIf="widgetService.selectedTab===5">
  <div class="payments__details-verify--left">
    <div class="d-flex align-center customer__details-cards" [ngStyle]="{'border-color': giftCardSettings.colourScheme.secondaryColor}">
      <div class="w-100">
        <h2 class="details__heading m-0">
          Restaurant
        </h2>
      </div>
      <div class="w-100 h-100">
        <h2 class="customer__purchase-details m-0">
          {{venueName}}
        </h2>
      </div>
    </div>
    <div class="d-flex align-center customer__details-cards mt-10" [ngStyle]="{'border-color': giftCardSettings.colourScheme.secondaryColor}">
      <div class="w-100">
        <h2 class="details__heading m-0">
          Card purchase
        </h2>
      </div>
      <div class="w-100 h-100">
        <h2 class="customer__purchase-details m-0">
          {{cardType}}
        </h2>
      </div>
    </div>
    <div class="d-flex align-center customer__details-cards mt-10" [ngStyle]="{'border-color': giftCardSettings.colourScheme.secondaryColor}">
      <div class="w-100">
        <h2 class="details__heading m-0">
          Total
        </h2>
      </div>
      <div class="w-100 h-100">
        <h2 class="customer__purchase-details m-0">
          ${{totalAmount}}
        </h2>
      </div>
    </div>
    <div class="customer__billing-details mt-20">
      <h2 class="billing__details-heading mt-0">
        Billing Details
      </h2>
      <div class="d-flex">
        <div class="w-30">
          <h2 class="customer__details-heading m-0">
            Name
          </h2>
        </div>
        <div>
          <h2 class="customer__details m-0">
            {{billingDetails.name}}
          </h2>
        </div>
      </div>
      <div class="d-flex mt-10">
        <div class="w-30">
          <h2 class="customer__details-heading m-0">
            Phone
          </h2>
        </div>
        <div>
          <h2 class="customer__details m-0">
            {{billingDetails.phoneNumber}}
          </h2>
        </div>
      </div>
      <div class="d-flex mt-10">
        <div class="w-30">
          <h2 class="customer__details-heading m-0">
            Email
          </h2>
        </div>
        <div>
          <h2 class="customer__details m-0">
            {{billingDetails.email}}
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div class="payments__details-verify--right ml-20">
    <div class="customer__recipient-details">
      <div>
        <h2 class="recipient__details-heading mt-0">
          Recipient Details
        </h2>
        <div class="d-flex">
          <div class="w-30">
            <h2 class="customer__details-heading m-0">
              Name
            </h2>
          </div>
          <div>
            <h2 class="customer__details m-0">
              {{recipientDetails.name}}
            </h2>
          </div>
        </div>
        <div class="d-flex mt-10">
          <div class="w-30">
            <h2 class="customer__details-heading m-0">
              Phone
            </h2>
          </div>
          <div>
            <h2 class="customer__details m-0">
              {{recipientDetails.phoneNumber}}
            </h2>
          </div>
        </div>
        <div class="d-flex mt-10">
          <div class="w-30">
            <h2 class="customer__details-heading m-0">
              Email
            </h2>
          </div>
          <div>
            <h2 class="customer__details m-0">
              {{recipientDetails.email}}
            </h2>
          </div>
        </div>
        <div *ngIf="cardType === 'Physical Card'" class="d-flex mt-10">
          <div class="w-30">
            <h2 class="customer__details-heading m-0">
              Address
            </h2>
          </div>
          <div>
            <h2 class="customer__details m-0">
              {{recipientDetails.address}}
            </h2>
          </div>
        </div>
        <div class="d-flex mt-10">
          <div class="w-30">
            <h2 class="customer__details-heading m-0">
              Message
            </h2>
          </div>
          <div class="message">
            <h2 class="customer__details m-0">
              {{recipientDetails.personalMessage}}
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex just-end mt-30">
  <div>
    <button class="cancel__button" [ngStyle]="{'border-color':this.giftCardSettings.colourScheme.primaryColor}" (click)="onBackClick()">Back</button>
  </div>
  <div>
    <button class="next__button ml-10" [ngStyle]="{'border-color':this.giftCardSettings.colourScheme.primaryColor}" (click)="onNextClick()">Pay Now</button>
  </div>
</div>

<ng-template #dialog  let-data let-ref="dialogRef">
  <nb-card class="stripe-dialog" >
    <nb-card-header>Pay</nb-card-header>
    <nb-card-body class="position-relative">
      <div
        *ngIf="!isLoaded"
        [nbSpinner]="!isLoaded"
        class="status-primary"
        nbSpinnerSize="giant"
        nbSpinnerStatus="primary"
        style="height: 200px; width: 100%; position: absolute;top: 0;left: 0"
      ></div>
      <ng-container>
        <input [(ngModel)]="nameOnCard" fullWidth nbInput placeholder="Name on Card">
        <div class="stripe-card-input mt-10">
          <ngx-stripe-card [elementsOptions]="elementsOptions" [options]="cardOptions" [stripe]="stripe"
                           class="w-100" #stripCard></ngx-stripe-card>
        </div>
      </ng-container>
    </nb-card-body>
    <nb-card-footer *ngIf="isLoaded">
      <button (click)="createToken()"  nbButton [disabled]="!isLoaded">Pay Now</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
