<div class="container">
  <div>
    <h2 class="gift-card__description d-flex just-center" [innerHTML]="giftCardSettings.message.giftCardWidgetDescription">
    </h2>
  </div>
  <div class="mt-20">
    <h2 class="card-purchase__question m-0">
      What card(s) would you like to purchase?
    </h2>
  </div>
  <div class="gift-card__types mt-20 gap-20 d-flex just-center">
    <div class="gift-card" (click)="onNextClick('eGiftCard')">
      <div class="d-flex just-between align-center">
        <div class="e-card__icon" [ngStyle]="{'background-color': primaryColor}">
          <img src="assets/images/e-card-icon.svg">
        </div>
        <div>
          <nb-icon class="gift-icon" icon="gift-outline"></nb-icon>
        </div>
      </div>
      <div>
        <h2 class="card__name-electronic m-0" [ngStyle]="{'color': primaryColor}">E-Gift Card</h2>
      </div>
      <div>
        <h2 class="card__description m-0" [innerHTML]="giftCardSettings.message.eGiftCardWidgetDescription">
        </h2>
      </div>
    </div>
    <div class="gift-card" *ngIf="giftCardSettings.physicalCard.isSellOnWidget" (click)="onNextClick('physicalCard')">
      <div class="d-flex just-between align-center">
        <div class="physical-card__icon" [ngStyle]="{'background-color': secondaryColor}">
          <img src="assets/images/physical-card-icon.svg">
        </div>
        <div>
          <nb-icon class="gift-icon" icon="gift-outline"></nb-icon>
        </div>
      </div>
      <div>
        <h2 class="card__name-physical m-0" [ngStyle]="{'color': secondaryColor}">Physical Card</h2>
      </div>
      <div>
        <h2 class="card__description m-0" [innerHTML]="giftCardSettings.message.physicalGiftCardWidgetDescription">
        </h2>
      </div>
    </div>
  </div>
</div>
