<div class="container">
  <div>
    <h2 class="card__type d-flex just-center">
      {{cardType}}: ${{totalAmount}}
    </h2>
  </div>
  <div>
    <h2 class="heading d-flex just-center mt-10 mb-0">
      Recipient Details
    </h2>
  </div>
  <div class="recipient__details mt-20">
    <form>
      <div class="name__phoneNumber-details d-flex just-between gap-20">
        <div class="w-100">
          <div class="d-flex">
            <label class="recipient__details-fonts">
              Name
            </label>
            <p class="m-0">*</p>
          </div>
          <div class="mt-5">
            <input type="text" placeholder="Name" class="recipient__details-input w-100" name="customerName"
                   [(ngModel)]="formData.name">
          </div>
        </div>
        <div class="w-100">
          <div class="d-flex">
            <label class="recipient__details-fonts">
              Phone
            </label>
            <p class="m-0">*</p>
          </div>
          <div class="mt-5">
            <app-phone-input [(phoneNumber)]="formData.phoneNumber"></app-phone-input>
          </div>
        </div>
      </div>
      <div class="w-100 mt-10">
        <div class="d-flex">
          <label class="recipient__details-fonts">
            Email
          </label>
          <p class="m-0">*</p>
        </div>
        <div class="mt-5">
          <input type="text" placeholder="Email" class="recipient__details-input w-100" name="email"
                 [(ngModel)]="formData.email">
        </div>
      </div>
      <div *ngIf="cardType === 'Physical Card'" class="w-100 mt-10">
        <div class="d-flex">
          <label class="recipient__details-fonts">
            Address
          </label>
          <p class="m-0">*</p>
        </div>
        <div class="mt-5">
          <input type="text" placeholder="Address" class="recipient__details-input w-100" name="address"
                 [(ngModel)]="formData.address">
        </div>
      </div>
      <div class="w-100 mt-10">
        <div class="d-flex">
          <label class="recipient__details-fonts">
            Add a Personal Message
          </label>
          <p class="m-0">*</p>
        </div>
        <div class="mt-5">
          <input type="text" placeholder="Message" class="recipient__details-input w-100" name="personalMessage"
                 [(ngModel)]="formData.personalMessage">
        </div>
      </div>
    </form>
    <div class="d-flex align-center mt-10">
      <nb-checkbox status="basic" class="terms__conditions-fonts" name="termsAndConditions"
                   [(ngModel)]="isAcceptedTermsAndConditions" required>
      </nb-checkbox>
      <div class="ml-10 cursor-pointer" (click)="isAcceptedTermsAndConditions=!isAcceptedTermsAndConditions">I agree to
        the
      </div>
      <a class="cursor-pointer ml-6" [nbPopover]="templateRef" [nbPopoverTrigger]="'click'"
         [nbPopoverPlacement]="'bottom-end'">terms and conditions.</a>
    </div>
  </div>
</div>
<div class="d-flex just-end mt-30">
  <div>
    <button class="cancel__button" [ngStyle]="{'border-color':this.giftCardSettings.colourScheme.primaryColor}"
            (click)="onBackClick()">Back
    </button>
  </div>
  <div>
    <button class="next__button ml-10" [ngClass]="{'button-disabled': !isAcceptedTermsAndConditions}"
            [ngStyle]="{'border-color':this.giftCardSettings.colourScheme.primaryColor}" (click)="onNextClick()">Next
    </button>
  </div>
</div>
<ng-template #templateRef>
  <div class="p-10" class="template-style">
    <p class="m-0 mb-10" [innerHTML]="giftCardSettings.message.giftCardTermsConditions"></p>
  </div>
</ng-template>
