<div [nbSpinner]="!isLoaded" nbSpinnerStatus="success" *ngIf="widgetService.selectedTab===1">
  <div>
    <h2 class="card__type d-flex just-center">
      {{cardType}}
    </h2>
  </div>
  <div class="gift__cards-collection d-flex just-center align-center f-wrap">
    <div class="gift__cards" *ngFor="let cards of giftCards;let i = index">
      <div class="gift__cards-inner">
        <div>
          <nb-icon class="gift-icon" icon="gift-outline" [ngStyle]="{'color': cardType === 'Physical Card' ?  'var(--secondaryColor)' : 'var(--primaryColor)'}"></nb-icon>
        </div>
        <div>
          <h2 class="gift__card-price m-0">
            {{cards.amount}}
          </h2>
        </div>
        <div class="d-flex align-center just-center gap-10 mt-10">
          <div class="plus__minus__icons-minus--icon">
            <nb-icon class="minus-icon" icon="minus-circle-outline" (click)="cardQuantityDecrement(i)"></nb-icon>
          </div>
          <input type="number" class="gift-cards__number" value="{{cards.quantity}}" readonly>
          <div class="plus__minus__icons-plus--icon">
            <nb-icon class="plus-icon" icon="plus-circle-outline" (click)="cardQuantityIncrement(i)"></nb-icon>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="gift__cards-footer mt-15">
    <div class="footer-inputs__buttons d-flex gap-20">
      <form [formGroup]="couponCodeForm" class="w-100">
        <div class="w-100">
          <input type="text" placeholder="Enter coupon code" class="coupon__code-input w-100" formControlName="couponCodeInput">
        </div>
      </form>
      <div class="cancel__next-buttons d-flex gap-20">
        <div>
          <button class="cancel__button" [ngStyle]="{'border-color':this.giftCardSettings.colourScheme.primaryColor}" (click)="onBackClick()">Back</button>
        </div>
        <div>
          <button class="next__button" [ngStyle]="{'border-color':this.giftCardSettings.colourScheme.primaryColor}" (click)="onNextClick()">Next</button>
        </div>
      </div>
    </div>
  </div>
</div>
