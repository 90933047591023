import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {WidgetService} from "../Services/widget.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {NotificationService, NotificationStatus} from "../Services/notification.service";

@Component({
  selector: 'app-gift-card-buying',
  templateUrl: './gift-card-buying.component.html',
  styleUrls: ['./gift-card-buying.component.scss']
})
export class GiftCardBuyingComponent implements OnInit , OnChanges{
  @Output() onNextClickChange = new EventEmitter();
  @Output() onBackClickChange = new EventEmitter();
  @Input() venueName;
  @Input() giftCardSettings;
  @Input() cardType;
  venueId;
  giftCards;
  couponCodeForm: FormGroup;
  cardId;
  selectedCard = [];
  giftCardSelectDate;
  giftCardSummary;
  isLoaded = false;
  constructor(public  widgetService: WidgetService,
              private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.widgetService.deleteCard.subscribe(id => {
      this.giftCards.forEach(giftcard => {
          if(giftcard._id === id){
            giftcard.quantity = 0;
          }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.cardType && changes.cardType.currentValue){
      this.cardType = this.widgetService.selectedGiftCardType;
      this.selectedCard = [];
      this.venueId = this.widgetService.getVenue();
      this.getAllGiftCard();
      this.couponCodeForm = new FormGroup({
        'couponCodeInput': new FormControl('', Validators.required)
      });
    }
  }

  onNextClick() {
    this.createSummary();
  }

  onBackClick() {
    this.onBackClickChange.emit();
  }
  cardQuantityIncrement(index) {
    this.giftCards[index].quantity++;
    if (this.giftCards[index].quantity === 1) {
      this.giftCardSelectDate = new Date();
      this.widgetService.setGiftCardDate(this.giftCardSelectDate);
    }
    this.cardId = this.giftCards[index]._id;
    const obj = {
      cardId: this.cardId,
      totalCards: this.giftCards[index].quantity
    };
    if (this.giftCards[index].quantity === 1) {
      this.selectedCard.push(obj);
    }
    if (this.giftCards[index].quantity > 1) {
      if (this.selectedCard[index] === undefined) {
        index = index - 1;
        this.selectedCard[index].totalCards++;
        index = index + 1;
      }
      else {
        this.selectedCard[index].totalCards++;
      }
    }
  }
  cardQuantityDecrement(index) {
    if (this.giftCards[index].quantity > 0) {
      this.giftCards[index].quantity--;
      this.selectedCard[index].totalCards--;
      if (this.selectedCard[index].totalCards === 0) {
        this.selectedCard.splice(1, index);
      }
    }
  }
  getAllGiftCard() {
    let giftCardType;
    if (this.cardType === 'E-Gift Card') {
      giftCardType = "E-Card";
    }
    else {
      giftCardType = "Physical-Card";
    }
    this.isLoaded = false;
    this.widgetService.getAllGiftCard(this.venueId, giftCardType)
      .then((res: any) => {
        this.giftCards = res;
        this.isLoaded = true;
        this.giftCards.forEach(function (value) {
          value.quantity = 0;
        });
      })
      .catch(err => {
        this.isLoaded = true;
        console.log(err);
      });
  }
  createSummary() {
    this.selectedCard = [];
    this.giftCards.forEach(giftcard => {
      if(giftcard.quantity > 0){
        this.selectedCard.push({
          cardId: giftcard._id,
          totalCards: giftcard.quantity
        });
      }
    });
    let reqBody = {
      cardType: this.cardType,
      couponCode: this.couponCodeForm.value.couponCodeInput,
      date: this.giftCardSelectDate,
      selectedCard: this.selectedCard
    };
    this.isLoaded = false;
    this.widgetService.createSummary(this.venueId, reqBody)
      .then((res: any) => {
        this.giftCardSummary = res;
        this.widgetService.setCartSummary(this.giftCardSummary);
        this.isLoaded = true;
        this.onNextClickChange.emit();
      })
      .catch((err: any) => {
        this.isLoaded = true;
        this.notificationService.showNotification(NotificationStatus.ERROR , err.error.message);
      });
  }
}
