import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {GiftCardWidgetComponent} from "./gift-card-widget/gift-card-widget.component";
import {VenueListComponent} from "./venue-list/venue-list.component";

const routes: Routes = [
  {
    path: 'widgets/online-widget/all-venue/:venueId',
    component: VenueListComponent
  },
  {
    path: 'gift-card/:venueId',
    component: GiftCardWidgetComponent,
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRouting{}
