import {Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, SimpleChanges, ViewChild} from '@angular/core';
import {WidgetService} from "../Services/widget.service";
import {StripeCardElementOptions, StripeElementsOptions} from "@stripe/stripe-js";
import {StripeCardComponent, StripeFactoryService, StripeInstance} from "ngx-stripe";
import {NbDialogRef, NbDialogService} from "@nebular/theme";
import {NotificationService, NotificationStatus} from "../Services/notification.service";

@Component({
  selector: 'app-gift-card-payment',
  templateUrl: './gift-card-payment.component.html',
  styleUrls: ['./gift-card-payment.component.scss']
})
export class GiftCardPaymentComponent implements OnInit {
  @Output() onNextClickChange = new EventEmitter();
  @Output() onBackClickChange = new EventEmitter();
  @Input() venueName;
  @Input() giftCardSettings;
  @Input() selectedIndex;
  cardType;
  recipientDetails;
  billingDetails;
  totalAmount;
  venueId;
  @ViewChild('dialog') cardDialog;
  stripe: StripeInstance;
  @ViewChild('stripCard') card: StripeCardComponent;
  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#66737f',
        color: '#66737f',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '0.9375rem',
        fontSmoothing: 'antialiased',
        padding: '10px',
        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: '#b8c1cc'
        }
      },
      invalid: {
        iconColor: '#fe5050',
        color: '#fe5050',
      },
    },
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
      },
    ],
  };
  nameOnCard = '';
  stripeKey;
  gateEwayName;
  stripeToken;
  isLoaded;
  constructor(public widgetService: WidgetService,
              private stripeFactory: StripeFactoryService,
              private notificationService: NotificationService,
              private dialogService: NbDialogService,
              @Optional() private dialogRef: NbDialogRef<Component>) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.selectedIndex && changes.selectedIndex.currentValue === 5){
      this.cardType = this.widgetService.selectedGiftCardType;
      this.totalAmount = this.widgetService.getCartSummary().totalAmount;
      this.recipientDetails = this.widgetService.recipientFormData;
      this.billingDetails = this.widgetService.billingFormData;
      this.venueId = this.widgetService.getVenue();
      this.gateEwayName = this.widgetService.getgateWayName();
      this.stripeKey = this.widgetService.widgetSettings.venueId.payment.stripeKeys.publishableKey;
      this.stripe = this.stripeFactory.create(this.stripeKey);
      this.isLoaded = true;
      this.venueName = this.widgetService.getSelectedVenue();
    }
  }

  onNextClick() {
    this.payment();
  }

  onBackClick() {
    this.onBackClickChange.emit();
  }
  payment() {
    const reqBody = {
      cardType: this.cardType,
      couponCode: '',
      date: this.widgetService.getGiftCardDate(),
      selectedCard: this.widgetService.getCartSummary().data,
      customerDetails: {
        buyer: {
          name: this.billingDetails.name,
          email: this.billingDetails.email,
          contactNo: this.billingDetails.phoneNumber
        },
        receiver: {
          name: this.recipientDetails.name,
          email: this.recipientDetails.email,
          contactNo: this.recipientDetails.phoneNumber,
          address: this.recipientDetails.address
        }
      },
      message: this.recipientDetails.personalMessage
    };
    if (this.gateEwayName === 'eway') {
      this.isLoaded = false;
      this.widgetService.getPaymentUrl(this.venueId, reqBody)
        .then((res: any) => {
          let url = res.url;
          this.isLoaded = true;
          window.open(url, '_blank');
          this.onNextClickChange.emit();
        })
        .catch(err => {
          this.isLoaded = true;
          console.log(err);
        });
    }
    else {
      this.open();
    }
  }
  open() {
    this.isLoaded = true;
    this.dialogRef = this.dialogService.open(this.cardDialog);
  }
  createToken(): void {
    const name = this.nameOnCard;
    this.isLoaded = false;
    this.stripe
      .createToken(this.card.element, {name})
      .subscribe((result) => {
        if (result.token) {
          // Use the token
          this.stripeToken = result.token.id;
          this.stripePay(result.token.id);
        } else if (result.error) {
          // Error creating the token
          this.isLoaded = true;
          console.log(result.error.message);
          this.notificationService.showNotification(NotificationStatus.ERROR, result.error.message);
        }
      });
  }
  stripePay(token) {
    const reqBody = {
      cardType: this.cardType,
      couponCode: '',
      date: this.widgetService.getGiftCardDate(),
      selectedCard: this.widgetService.getCartSummary().data,
      stripeToken: this.stripeToken,
      customerDetails: {
        buyer: {
          name: this.billingDetails.name,
          email: this.billingDetails.email,
          contactNo: this.billingDetails.phoneNumber
        },
        receiver: {
          name: this.recipientDetails.name,
          email: this.recipientDetails.email,
          contactNo: this.recipientDetails.phoneNumber,
          address: this.recipientDetails.address
        },
      },
      message: this.recipientDetails.personalMessage
    };
    this.widgetService.stripeGiftCardPayment(this.venueId, reqBody)
      .then((res: any) => {
        this.closeDialog();
        this.isLoaded = true;
        this.onNextClickChange.emit();
      })
      .catch(err => {
        this.isLoaded = true;
        console.log(err);
        this.notificationService.showNotification(NotificationStatus.ERROR, err.error.message);
      });
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
