<nb-layout *ngIf="loading">
  <nb-layout-column>
    <div
      class="logout-spinner ml-10 h-100 w-100"
      [nbSpinner]="loading"
      nbSpinnerStatus="primary"
      nbSpinnerSize="giant"
    ></div>
  </nb-layout-column>
</nb-layout>
<nb-layout *ngIf="!loading">
  <nb-layout-column>
    <div class="bottom-main-div">
      <div class="header-div"></div>
      <div class="preview-content p-50">
        <div class="d-flex just-center">
          <div class="logo-image-div">
            <img [src]="giftCardSettings?.venueId?.logo">
          </div>
        </div>
        <div>
          <h2 class="header__description d-flex just-center mt-20">
            {{venueName}}
          </h2>
        </div>
        <app-gift-card-type-selection
                                      (onNextClickChange)="onNextClick()"
                                      (onBackClickChange)="onBackClick()"
                                      [venueName]="venueName"
                                      [giftCardSettings]="giftCardSettings"
                                      [primaryColor]="primaryColor"
                                      [secondaryColor]="secondaryColor"
                                      [hidden]="bookingStep !== 0"
                                      class="w-100">
        </app-gift-card-type-selection>
        <app-gift-card-buying  (onNextClickChange)="onNextClick()"
                               (onBackClickChange)="onBackClick()"
                               [giftCardSettings]="giftCardSettings"
                               [venueName]="venueName"
                               [hidden]="bookingStep !== 1"
                               [cardType]="widgetService.selectedGiftCardType"
                                class="w-100">
        </app-gift-card-buying>
        <app-gift-card-cart (onNextClickChange)="onNextClick()"
                            (onBackClickChange)="onBackClick()"
                            [giftCardSettings]="giftCardSettings"
                            [selectedIndex] = "bookingStep"
                            [venueName]="venueName"
                            [hidden]="bookingStep !== 2"
                            class="w-100">
        </app-gift-card-cart>
        <app-gift-card-recipient-details (onNextClickChange)="onNextClick()"
                                         (onBackClickChange)="onBackClick()"
                                         [giftCardSettings]="giftCardSettings"
                                         [venueName]="venueName"
                                         [selectedIndex] = "bookingStep"
                                         [hidden]="bookingStep !== 3"
                                          class="w-100">
        </app-gift-card-recipient-details>
        <app-gift-card-billing-details (onNextClickChange)="onNextClick()"
                                       (onBackClickChange)="onBackClick()"
                                       [giftCardSettings]="giftCardSettings"
                                       [venueName]="venueName"
                                       [selectedIndex] = "bookingStep"
                                       [hidden]="bookingStep !== 4"
                                       class="w-100">
        </app-gift-card-billing-details>
        <app-gift-card-payment (onNextClickChange)="onNextClick()"
                               (onBackClickChange)="onBackClick()"
                               [giftCardSettings]="giftCardSettings"
                               [venueName]="venueName"
                               [selectedIndex] = "bookingStep"
                               [hidden]="bookingStep !== 5"
                               class="w-100">
        </app-gift-card-payment>
        <app-gift-card-greetings [hidden]="bookingStep !== 6"
                                 [selectedIndex] = "bookingStep"
                                 [giftCardSettings]="giftCardSettings">
        </app-gift-card-greetings>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>

