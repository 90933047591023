<div class="container">
  <div>
    <h2 class="card__type d-flex just-center">
      {{cardType}}: ${{totalAmount}}
    </h2>
  </div>
  <div>
    <h2 class="heading d-flex just-center mt-10 mb-0">
      Billing Details
    </h2>
  </div>
  <div class="billing__details mt-20">
    <form>
      <div class="name__phoneNumber-details d-flex just-between gap-20">
        <div class="w-100">
          <div class="d-flex">
            <label class="billing__details-fonts">
              Name
            </label>
            <p class="m-0">*</p>
          </div>
          <div>
            <input type="text" placeholder="Name" class="billing__details-input w-100" name="customerName"
                   [(ngModel)]="formData.name" required>
          </div>
        </div>
        <div class="w-100">
          <div class="d-flex">
            <label class="billing__details-fonts">
              Phone
            </label>
            <p class="m-0">*</p>
          </div>
          <div class="mt-5">
            <app-phone-input [(phoneNumber)]="formData.phoneNumber"></app-phone-input>
          </div>
        </div>
      </div>
      <div class="w-100 mt-10">
        <div class="d-flex">
          <label class="billing__details-fonts">
            Email
          </label>
          <p class="m-0">*</p>
        </div>
        <div class="mt-5">
          <input type="email" placeholder="Email" class="billing__details-input w-100" name="email"
                 [(ngModel)]="formData.email" required>
        </div>
      </div>
      <div class="d-flex align-center mt-20">
        <nb-checkbox status="basic" class="terms__conditions-fonts" name="termsAndConditions"
                     [(ngModel)]="isAcceptedTermsAndConditions" required>
        </nb-checkbox>
        <div class="ml-10 cursor-pointer" (click)="isAcceptedTermsAndConditions=!isAcceptedTermsAndConditions">I agree
          to the Bookya
        </div>
        <a href="https://bookya.com.au/privacy-policy" target="_blank" class="cursor-pointer ml-6">terms and
          conditions.</a>
      </div>
    </form>
  </div>
</div>
<div class="cancel__next-buttons d-flex just-end mt-30">
  <div>
    <button class="cancel__button" [ngStyle]="{'border-color':this.giftCardSettings.colourScheme.primaryColor}"
            (click)="onBackClick()">Back
    </button>
  </div>
  <div>
    <button class="next__button ml-10" [ngStyle]="{'border-color':this.giftCardSettings.colourScheme.primaryColor}"
            [ngClass]="{'button-disabled': !isAcceptedTermsAndConditions}" (click)="onNextClick()">Next
    </button>
  </div>
</div>
