import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {WidgetService} from "../Services/widget.service";
import {Form, NgForm} from "@angular/forms";
import {NotificationStatus , NotificationService} from '../Services/notification.service';
import {BookingValidationHelper} from "../../utils/helper/validation.helper";

@Component({
  selector: 'app-gift-card-recipient-details',
  templateUrl: './gift-card-recipient-details.component.html',
  styleUrls: ['./gift-card-recipient-details.component.scss']
})
export class GiftCardRecipientDetailsComponent implements OnInit , OnChanges{
  @Output() onNextClickChange = new EventEmitter();
  @Output() onBackClickChange = new EventEmitter();
  @Input() venueName;
  @Input() giftCardSettings;
  @Input() selectedIndex;
  cardType;
  phoneNumber;
  formData = {
    'name' : '',
    'phoneNumber': '+61-',
    'email': '',
    'address': '',
    'personalMessage': ''
  };
  isAcceptedTermsAndConditions = false;
  totalAmount = 0;
  constructor(private widgetService: WidgetService,
              private notificationService: NotificationService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.selectedIndex && changes.selectedIndex.currentValue === 3){
      this.cardType = this.widgetService.selectedGiftCardType;
      this.totalAmount = this.widgetService.getCartSummary().totalAmount;
    }
  }

  onNextClick() {
    const error = new BookingValidationHelper().customerValidation(this.formData);
    if (error && error.errStatus) {
      this.notificationService.showNotification(NotificationStatus.ERROR, error.error);
      return true;
    }
    if(!this.isAcceptedTermsAndConditions){
      this.notificationService.showNotification('warning', "You need to agree to the terms and conditions before proceeding");
      return;
    }
    this.widgetService.recipientFormData = this.formData;
    this.onNextClickChange.emit();
  }
  onBackClick() {
    this.onBackClickChange.emit();
  }
}
