import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Urls} from "../../utils/urls";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  selectedGiftCardType;
  recipientFormData;
  billingFormData;
  widgetSettings;
  selectedTab = 0;
  deleteCard = new Subject();
  constructor(private http: HttpClient) { }

  setVenue(venueId) {
    localStorage.setItem('venueId', venueId);
  }
  getVenue() {
    return localStorage.getItem('venueId');
  }
  setSelectedVenue(venue) {
    localStorage.setItem('venue', venue);
  }
  getSelectedVenue() {
    return localStorage.getItem('venue');
  }
  setCartSummary(res) {
    let cartSummary = JSON.stringify(res);
    localStorage.setItem('Summary', cartSummary);
  }
  getCartSummary() {
    return JSON.parse( localStorage.getItem('Summary'));
  }
  setGiftCardDate(date) {
    localStorage.setItem('giftCardDate', date);
  }
  getGiftCardDate() {
    return localStorage.getItem('giftCardDate');
  }
  setgateWayName(name) {
    localStorage.setItem("gateWayName", name);
  }
  getgateWayName() {
    return localStorage.getItem("gateWayName");
  }
  getWidgetSettings(venueId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(Urls.widget + '/' + venueId )
        .subscribe(
          (res: any) => {
            resolve(res.data.giftcard);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  getAllGiftCard(venueId, cardType) {
    return new Promise((resolve, reject) => {
      this.http
        .get(Urls.baseUrl + 'gift-card/' + venueId,{ params: new HttpParams().set('giftCardType', cardType)})
        .subscribe(
          (res: any) => {
            resolve(res.data);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  createSummary(venueId, reqbody) {
    return new Promise((resolve, reject) => {
      this.http
        .put(Urls.baseUrl + 'gift-card-transaction/' + venueId + '/create-summary', reqbody)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  getPaymentUrl(venueId,reqbody) {
    return new Promise((resolve, reject) => {
      this.http
        .post(Urls.baseUrl + 'gift-card-transaction/' + venueId + '/eway-payment-url', reqbody)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }
  stripeGiftCardPayment(venueId, reqBody) {
    return new Promise((resolve, reject) => {
      this.http
        .post(Urls.baseUrl + 'gift-card-transaction/' + venueId + '/stripe-gift-card-payment', reqBody)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }
}
