import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CountryService} from "../country.service";

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss']
})
export class PhoneInputComponent implements OnInit, OnChanges {
  codeList;
  @Input() phoneNumber;
  phone;
  selectedCode;
  @Output() phoneNumberChange = new EventEmitter();
  constructor(private countryService: CountryService) { }

  ngOnInit(): void {
    this.codeList = this.countryService.getCountryCodes();
    this.setNumber(this.phoneNumber);
  }

  restrictNumber(event) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onNumberChanged(event) {
    if (this.selectedCode) {
      this.concatNumber();
      this.phoneNumberChange.emit(this.phoneNumber);
    }

  }

  onCodeChange(event: any) {
    if (this.phone && this.phone.trim().length) {
      this.concatNumber();
      this.phoneNumberChange.emit(this.phoneNumber);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.phoneNumber && this.codeList && this.codeList.length) {
      this.setNumber(changes.phoneNumber.currentValue)
    }
  }
  setNumber(data) {
    this.selectedCode = this.codeList.find(country => country.callingCodes === data.split("-")[0]);
    this.phone = data.split("-")[1];
  }
  concatNumber() {
    this.phoneNumber = this.selectedCode.callingCodes + "-" + this.phone;
  }

}
