import {NgModule} from '@angular/core';
import {
  NbButtonModule,
  NbCalendarModule, NbCardModule, NbDialogModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule, NbSelectModule, NbSpinnerModule,
  NbToastrModule
} from '@nebular/theme';

@NgModule({
  imports: [
    NbCalendarModule,
    NbLayoutModule,
    NbButtonModule,
    NbIconModule,
    NbInputModule,
    NbFormFieldModule,
    NbSelectModule,
    NbToastrModule.forRoot(),
    NbDialogModule.forChild(),
    NbCardModule,
    NbDialogModule,
    NbSpinnerModule
  ],
  exports: [
    NbCalendarModule,
    NbLayoutModule,
    NbButtonModule,
    NbIconModule,
    NbInputModule,
    NbToastrModule,
    NbFormFieldModule,
    NbSelectModule,
    NbDialogModule
  ]
})
export class NebularModule{

}
